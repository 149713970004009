@import "../../styles/utils";
@import "../../styles/vars";

$z-layer-0: 3000;
$z-layer-1: 3001;
$z-layer-2: 3002;

$z-section-parallax-0: 4000;
$z-section-parallax-1: 4001;
$z-section-parallax-2: 4002;

.page_bg {
  background-color: #6ed4ff;
}
.pp_section {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: $z-section-parallax-1;
}
.section1 {
  .shirt_video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #3d8bd9
      url("../../assets/product-pages/zen/shirt_video_placeholder.png");
    background-size: cover;
  }
  .logo_rhs {
    position: absolute;
    width: 50%;
    top: 0;
    bottom: 0;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
      width: 100%;
      left: 0;
    }
    .zenLogo {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -85px;
      margin-top: -99px;
    }
  }
}
.section_bg {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: $z-section-parallax-0;
  background: url("../../assets/product-pages/zen/cloud_bg.png");
  background: url("../../assets/product-pages/zen/cloud_bg.png"),
    linear-gradient(180deg, #3d86d3 0%, #6ed3ff 100%);
  background-repeat: no-repeat;
  background-size: cover;
}

.section2 {
  position: relative;
  .lhs {
    @include media-breakpoint-down(md) {
      position: absolute;
      width: 100%;
    }
  }
  .rhs {
    @include media-breakpoint-down(md) {
      position: absolute;
      width: 100%;
      h5 {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
      }
    }
  }
}
.section3 {
  @include media-breakpoint-down(md) {
    height: auto;
  }
  .features_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    @include media-breakpoint-down(md) {
      height: auto !important;
      flex-direction: column;
    }
  }
  .feature_card {
    text-align: center;
    text-transform: uppercase;
    padding: 3rem;
    @include media-breakpoint-down(md) {
      padding: 2rem;
      padding-left: 4rem;
      padding-right: 4rem;
    }
    p {
      @include poppins-semi-bold;
      font-size: 0.8rem;
      margin-top: 2rem;
    }
  }
}
.section4 {
  h3 {
    @include poppins-medium;
  }
  @include media-breakpoint-down(md) {
    height: auto;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}
.product_section {
  z-index: $z-section-parallax-2;
  .product_plaque_image {
    width: 100%;
    height: auto;
    max-width: 360px;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
  .product_plaque {
    background-color: #222222;
    padding: 0 4rem;
    .title {
      @include poppins-semi-bold;
    }
    .description {
      color: theme-color("light");
    }
    @include media-breakpoint-down(md) {
      padding: 4rem;
    }
  }
}
