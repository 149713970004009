$font-family-sans-serif: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

$btn-line-height: 1.5rem;

$btn-font-family: Poppins;
$btn-font-weight: 600;
$btn-font-size: 0.75rem;

// $headings-font-family: Poppins;
$enable-responsive-font-sizes: true;

$primary: #ea0032;
$secondary: #cacaca;
$success: #015668;
$danger: #06648c;
$info: #0f81c7;
$warning: #0de2ea;
$light: #adadad;
$dark: #222222;

$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 1.25rem !default;

$spacer: 1.2rem;
$btn-border-radius: 1.5rem;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
