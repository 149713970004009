@import "../../styles/utils";
@import "../../styles/vars";

$z-layer-0: 3000;
$z-layer-1: 3001;
$z-layer-2: 3002;

$z-section-parallax-0: 4000;
$z-section-parallax-1: 4001;
$z-section-parallax-2: 4002;

.page_bg {
  background-color: #cac9c7;
}
.pp_section {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: $z-section-parallax-1;
}
.section_bg {
  position: absolute;
  top: 0;
  overflow: hidden;
  .bg {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    background: url("../../assets/product-pages/inara/inarabg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: $z-section-parallax-0;
  }
}
.section1 {
  height: 100vh;
  .lhs {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rhs {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .logo_container {
    position: absolute;
    .logo {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img:first-child {
        margin-bottom: 1rem;
      }
    }
  }
  .product_description {
    position: absolute;
    background-color: #525252;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 400px;
    height: 400px;
    padding: 2rem;
  }
}
.section2 {
  .featureItem {
    max-width: 75%;
    margin: 0px auto;
    text-align: center;
    img {
      max-width: 100%;
      box-shadow: 0px 24px 36px rgba(0, 0, 0, 0.15);
      margin-bottom: 2rem;
    }
  }
}
.section3 {
  h3 {
    @include poppins-medium;
  }
  @include media-breakpoint-down(md) {
    height: auto;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}
.product_section {
  z-index: $z-section-parallax-2;
  .product_plaque_image {
    width: 100%;
    height: auto;
    max-width: 360px;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
  .product_plaque {
    background-color: #222222;
    padding: 0 4rem;
    .title {
      @include poppins-semi-bold;
    }
    .description {
      color: theme-color("light");
    }
    @include media-breakpoint-down(md) {
      padding: 4rem;
    }
  }
}
