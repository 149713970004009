@import "../../styles/utils";
@import "../../styles/vars";

$z-layer-0: 3000;
$z-layer-1: 3001;
$z-layer-2: 3002;

$z-section-parallax-0: 4000;
$z-section-parallax-1: 4001;
$z-section-parallax-2: 4002;

.page_bg {
  background: #efefef url("../../assets/product-pages/bg_tile.png");
}
.debug {
  background-color: #c0c0c0;
  color: #333;
  position: absolute;
  top: 0;
  left: 0;
}
.pp_section {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: $z-section-parallax-1;
}
.section1 {
  z-index: $z-section-parallax-0;
  .content_bar {
    position: absolute;
    width: 100%;
    height: 60px;
    left: 0px;
    top: 50%;
    margin-top: -30px;

    background: #f9f9f9;
    z-index: $z-layer-0;
    @include media-breakpoint-down(md) {
      z-index: $z-layer-2;
      background-color: $black;
      opacity: 0.8;
    }
  }
  .lhs {
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .model {
      height: 100%;
      z-index: $z-layer-1;
    }
  }
  .rhs {
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .ellipse {
      z-index: $z-layer-1;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;

      background: radial-gradient(
        circle closest-side,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      @include media-breakpoint-down(md) {
        z-index: $z-layer-0;
      }
    }
    .product_brand {
      position: absolute;
      display: inline-block;
      padding-right: 6rem;
      z-index: $z-layer-2;
      h2 {
        font-family: "Butler Medium";
        font-size: 5rem;
        span {
          @include butler-bold;
          font-size: 1.5rem;
          position: absolute;
          left: 25%;
          top: 0;
        }
        @include media-breakpoint-down(md) {
          color: $white;
          font-size: 4rem;
          span {
            font-size: 1.3rem;
            left: 20%;
          }
        }
      }
      span.subtitle {
        color: #666666;
        text-transform: uppercase;
        position: absolute;
        bottom: 0.8rem;
        right: 0%;
        font-size: 1rem;
        @include media-breakpoint-down(md) {
          font-size: 0.9rem;
          color: $white;
        }
      }
    }
    .product_description {
      position: absolute;
      text-align: center;
      padding-left: 4rem;
      padding-right: 4rem;
      z-index: $z-layer-2;
      .title {
        color: #454545;
        font-weight: 500;
        font-size: 1.3rem;
      }
      .bodyText {
        color: #666666;
        strong {
          font-weight: 600;
        }
      }
      hr {
        background-color: #666666;
      }
      .carbon {
        width: 60%;
        margin-top: 1.5rem;
        h5 {
          font-size: 1rem;
          margin-bottom: 0.2rem;
        }
        p {
          font-size: 0.75rem;
        }
      }
      @include media-breakpoint-down(md) {
        padding-left: 2rem;
        padding-right: 2rem;
        .title {
          color: $white;
        }
        .bodyText {
          color: #c4c4c4;
        }
        hr {
          background-color: $white;
        }
        .carbon {
          color: $white;
          width: 75%;
        }
      }
    }
  }
}

.section2 {
  .img_container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    h3 {
      @include poppins-semi-bold;
      font-size: 2.5rem;
      position: absolute;
      z-index: 10001;
      color: #ffffff;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
      transform: translate(0%, -50%);
      margin: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 0% 0%;
      transition: transform 1.2s ease;
    }
    &:hover img {
      transform: scale(1.1) !important;
    }
  }
}
.section3 {
  h3 {
    @include poppins-medium;
  }
  @include media-breakpoint-down(md) {
    height: auto;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}
.section4 {
  .product_plaque_image {
    width: 100%;
    height: auto;
    max-width: 360px;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
  .product_plaque {
    background-color: #222222;
    padding: 0 4rem;
    .title {
      @include poppins-semi-bold;
    }
    .description {
      color: theme-color("light");
    }
    @include media-breakpoint-down(md) {
      padding: 4rem;
    }
  }
}
