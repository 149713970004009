@import "../../styles/utils";
@import "../../styles/vars";

$z-layer-0: 3000;
$z-layer-1: 3001;
$z-layer-2: 3002;

$z-section-parallax-0: 4000;
$z-section-parallax-1: 4001;
$z-section-parallax-2: 4002;

.page_bg {
  background-color: #e8e8e8;
}
.pp_section {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: $z-section-parallax-1;
}
.section1 {
  .section1_bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../../assets/product-pages/shibui/section1_bg.png");
    background-size: cover;
    @include media-breakpoint-down(md) {
      background-position-x: 80%;
    }
  }
  .logo_lhs {
    position: absolute;
    width: 50%;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
      width: 100%;
      left: 0;
    }
    .shibuiLogo {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -115px;
      margin-top: -75px;
    }
  }
}
.section2 {
  @include media-breakpoint-down(md) {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .section2_bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../../assets/product-pages/shibui/section2_bg.png");
    background-size: cover;
    @include media-breakpoint-down(md) {
      position: relative;
      width: 100%;
      flex: 1;
      background-position-x: 15%;
    }
  }
  .rhs {
    position: absolute;
    width: 50%;
    top: 0;
    bottom: 0;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #222222;
    padding: 3rem;
    text-align: center;
    @include media-breakpoint-down(md) {
      width: 100%;
      left: 0;
      position: relative;
      background: #293a5a;
      color: #e8e8e8;
    }
  }
}
.section3 {
  position: relative;
  height: auto;
  background: #f0f0f0;
  .section3_parallax_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../assets/product-pages/shibui/desk_bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: $z-section-parallax-0;
  }
  .features_container {
    text-align: center;
    z-index: $z-section-parallax-1;
    position: relative;
    padding: 2rem;
    padding-top: 25%;
    padding-bottom: 4rem;
    margin: 0px auto;
    .feature_box {
      display: inline-block;
      margin-bottom: 4rem;
      box-shadow: 0px 24px 36px rgba(0, 0, 0, 0.07);
      img {
        width: 100%;
        height: auto;
        max-width: 720px;
      }
      p {
        background: #ffffff;
        padding: 1rem;
        margin: 0;
      }
    }
  }
}
.section4 {
  @include media-breakpoint-down(md) {
    height: auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  h3 {
    @include poppins-medium;
  }
  .titleContainer {
    width: 80%;
    margin: 0px auto;
  }
  .videos_container {
    text-align: center;
    z-index: $z-section-parallax-1;
    position: relative;
    width: 100%;
    overflow: hidden;
    @include media-breakpoint-down(md) {
      overflow-x: scroll;
    }
    .videos {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-left: 10%;
    }
    .video_box {
      text-align: center;
      margin-right: 4rem;
      min-width: 500px;
      border-radius: 0.25rem;
      overflow: hidden;
      box-shadow: 0px 24px 36px rgba(0, 0, 0, 0.07);
      @include media-breakpoint-down(md) {
        min-width: 250px;
        margin-right: 2rem;
      }
      img,
      video {
        width: 100%;
        height: auto;
      }
      p {
        font-size: 0.75rem;
        background: #222222;
        color: #ffffff;
        padding: 1rem;
        margin: 0;
      }
    }
  }
}
.product_section {
  z-index: $z-section-parallax-2;
  .product_plaque_image {
    width: 100%;
    height: auto;
    max-width: 360px;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
  .product_plaque {
    background-color: #222222;
    padding: 0 4rem;
    .title {
      @include poppins-semi-bold;
    }
    .description {
      color: theme-color("light");
    }
    @include media-breakpoint-down(md) {
      padding: 4rem;
    }
  }
}
