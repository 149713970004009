@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
@import url("https://rsms.me/inter/inter.css");

@font-face {
  font-family: "Butler Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Butler Regular"),
    url("../fonts/Butler_Regular.woff") format("woff");
}
@font-face {
  font-family: "Butler UltraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Butler UltraLight"),
    url("../fonts/Butler_Ultra_Light.woff") format("woff");
}
@font-face {
  font-family: "Butler Light";
  font-style: normal;
  font-weight: normal;
  src: local("Butler Light"), url("../fonts/Butler_Light.woff") format("woff");
}
@font-face {
  font-family: "Butler Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Butler Medium"), url("../fonts/Butler_Medium.woff") format("woff");
}
@font-face {
  font-family: "Butler Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Butler Bold"), url("../fonts/Butler_Bold.woff") format("woff");
}
@font-face {
  font-family: "Butler ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local("Butler ExtraBold"),
    url("../fonts/Butler_ExtraBold.woff") format("woff");
}
@font-face {
  font-family: "Butler Black";
  font-style: normal;
  font-weight: normal;
  src: local("Butler Black"), url("../fonts/Butler_Black.woff") format("woff");
}
