@import "./vars";
@import "~bootstrap/scss/bootstrap.scss";

.btn-primary {
  &.btn-glow {
    box-shadow: 0px 0px 20px rgba(234, 0, 50, 0.6);
  }
}

.btn-ucase {
  text-transform: uppercase;
}
.h-md-100 {
  @include media-breakpoint-up(md) {
    height: 100% !important;
  }
}
.h-md-50 {
  @include media-breakpoint-up(md) {
    height: 50% !important;
  }
}
